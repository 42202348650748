// global color variables
$primary: #184b85;
$warning: #ffcb3c;
$sidebar_width: 140px;

@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/spinners";
// Helpers
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/transitions";

// Utilities
@import "bootstrap/scss/utilities/api";

@import "./ng_wizard.css";

@import '@ng-select/ng-select/themes/default.theme.css';

@include mat.core();
$theme: mat.m2-define-light-theme((
  color: (
    primary: mat.m2-define-palette(mat.$m2-deep-purple-palette),
    accent: mat.m2-define-palette(mat.$m2-amber-palette)
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
));

$table-striped-order:         even;

html {
  height: 100%;
  @include mat.core-theme($theme);
  @include mat.radio-theme($theme);
  @include mat.button-theme($theme);
  @include mat.tabs-theme($theme);
  @include mat.input-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.button-toggle-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.select-theme($theme);
}

body {
  font-size: .875rem;
  height: 100%;
}

table.table-striped {
  --bs-table-striped-bg: #eff7ff;
  --bs-table-hover-bg: #ffe9c5;
  --bs-table-striped-order: even;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 52px 0 0;
  flex: 0 0 auto;
  width: $sidebar_width;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

  @media (max-width: 722px) {
    & {
      top: 2rem;
    }
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}


/*
 * Navbar
 */
.sticky-top {
  z-index: 999;
}

.navbar {
  background: #184b85;
  color: #fff;

  i-feather.sm {
    width: 16px;
    height: 16px;
  }
}

.navbar-brand {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1rem;
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

i-feather {
  &.sm {
    width: 20px;
    height: 20px;
  }
  &.xsm {
    width: 16px;
    height: 16px;
  }
  &.sm .feather,
  &.xsm .feather {
    vertical-align: text-bottom;
  }
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.82);
  z-index: 9999999;
}

.loader-contents {
  width: 100%;
  height: 100%;
  transform: translate(calc(50% - 50px), calc(50% - 50px));
}

mat-dialog-container {
  position: relative;
  overflow: hidden !important;
}

mat-dialog-content {
  border-bottom: 1px solid #ddd;
  height: calc(100% - 95px) !important;
  overflow: hidden;
}

mat-tab-group {
  height: 100%;
}

.transparent-bg {
  background-color: #fff;
  background-image: linear-gradient(45deg, #ddd 25%, transparent 25%, transparent 75%, #ddd 75%, #ddd), linear-gradient(45deg, #ddd 25%, transparent 25%, transparent 75%, #ddd 75%, #ddd);
  background-size: 8px 8px;
  background-position: 0 0, 4px 4px;
}

button.mat-button .mat-icon,
button.mat-raised-button .mat-icon,
button.mat-flat-button .mat-icon,
button.mat-stroked-button .mat-icon {
  vertical-align: top;
  position: relative;
  top: 3px;
  left: -3px;
}

.screen {
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
}
.data-grid {
  display: flex;
  flex-direction: column;
}
.btn-max-w-128 {
  max-width: 128px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tab-header {
  margin: 0 auto 0 0;
}

table thead th .subtitle {
  font-weight: 400;
}

main.main-default {
  width: calc(100% - $sidebar_width);

  @media (max-width: 767.98px) {
    & {
      width: 100%;
    }
  }
}

@media (max-height: 460px) {
  ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 140px;
  }
}
