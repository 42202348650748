.ng-wizard-main {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  border-radius: 0.25rem !important;
}

.ng-wizard-main .ng-wizard-container {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
}

.ng-wizard-main .step-content {
  display: none;
  position: relative;
  margin: 0;
}

.ng-wizard-main .ng-wizard-toolbar {
  margin-left: 0;
}

/* SmartWizard Theme: White */
.ng-wizard-theme-default {

}

.ng-wizard-theme-default .ng-wizard-container {
  min-height: 250px;
}

.ng-wizard-theme-default .step-content {
  padding: 10px 0;
  border: 0 solid #d4d4d4;
  background-color: #fff;
  text-align: left;
}

.ng-wizard-theme-default .ng-wizard-toolbar {
  background: #f9f9f9;
  border-radius: 0 !important;
  padding-left: 10px;
  padding-right: 10px;
  padding: 10px;
  margin-bottom: 0 !important;
}

.ng-wizard-theme-default .ng-wizard-toolbar-top {
  border-bottom-color: #ddd !important;
}

.ng-wizard-theme-default .ng-wizard-toolbar-bottom {
  border-top-color: #ddd !important;
}

.ng-wizard-theme-default > ul.step-anchor {
  display: flex;
}

.ng-wizard-theme-default > ul.step-anchor > li {
  flex: 1;
  font-size: 16px;
  position: relative;
  margin-right: 2px;
}

.ng-wizard-theme-default > ul.step-anchor > li > a,
.ng-wizard-theme-default > ul.step-anchor > li > a:hover {
  border: none !important;
  color: #bbb;
  text-decoration: none;
  outline-style: none;
  background: transparent !important;
  cursor: not-allowed;
}

.ng-wizard-theme-default > ul.step-anchor > li.clickable > a:hover {
  color: #4285f4 !important;
  background: transparent !important;
  cursor: pointer;
}

.ng-wizard-theme-default > ul.step-anchor > li > a::after {
  content: "";
  background: #ffcb3c;
  height: 4px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  -webkit-transition: all 250ms ease 0s;
  transition: all 250ms ease 0s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.ng-wizard-theme-default > ul.step-anchor > li.active > a {
  font-weight: 600;
  border: none !important;
  color: #184b85 !important;
  background: transparent !important;
  cursor: pointer;
}

.ng-wizard-theme-default > ul.step-anchor > li.active > a::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.ng-wizard-theme-default > ul.step-anchor > li.done > a {
  border: none !important;
  color: #000 !important;
  background: transparent !important;
  cursor: pointer;
}

.ng-wizard-theme-default > ul.step-anchor > li.done > a::after {
  background: #184b85;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.ng-wizard-theme-default > ul.step-anchor > li.danger > a {
  border: none !important;
  color: #d9534f !important;
  /* background: #d9534f !important; */
  cursor: pointer;
}

.ng-wizard-theme-default > ul.step-anchor > li.danger > a::after {
  background: #d9534f;
  border-left-color: #f8d7da;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.ng-wizard-theme-default > ul.step-anchor > li.disabled > a,
.ng-wizard-theme-default > ul.step-anchor > li.disabled > a:hover {
  color: #eee !important;
  cursor: not-allowed;
}

/* Responsive CSS */
@media screen and (max-width: 768px) {
  .ng-wizard-theme-default > .nav-tabs > li {
    float: none !important;
  }
}

/* Common Loader */
.ng-wizard-loading::after {
  position: absolute;
  display: block;
  opacity: 1;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 2;
}

.ng-wizard-loading::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
